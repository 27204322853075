import { WhlModuleType } from '@models/WhlModuleType';

export default defineNuxtRouteMiddleware((_, from) => {
  if (import.meta.server) return;

  let moduleType = null;

  const pathSegments = from.path.split('/');
  const moduleSegment = pathSegments[pathSegments.length - 2];

  switch (moduleSegment) {
    case 'events':
      moduleType = WhlModuleType.EVENT;
      break;
    case 'pois':
      moduleType = WhlModuleType.POI;
      break;
    case 'tours':
      moduleType = WhlModuleType.TOUR;
      break;
    default:
      moduleType = null;
      break;
  }

  if (moduleType && import.meta.client) {
    sessionStorage.setItem('previousWhlModuleType', moduleType);
  }
});
